export default {
    continue: 'Продолжить',
    registration: 'Регистрация',
    auth: 'Авторизация',
    signIn: 'Войти',
    register: 'Зарегистрироваться',
    login: 'Логин',
    phoneNumber: 'Номер телефона',
    password: 'Пароль',
    confirmPassword: 'Подтверждение пароля',
    noData: 'Данных нет',
    create: 'Создать',
    add: 'Добавить',
    more: 'Подробнее',
    status: 'Статус',
    remove: 'Удалить',
    errors: {
        serverError: 'Ошибка на сервере',
    },
    titles: {
        home: 'Главная',
        registration: 'Регистрация',
        auth: 'Авторизация',
        humans: 'Студенты',
        users: 'Пользователи',
        entrances: 'Общежития',
        turnstiles: 'Турникеты',
        reports: 'Отчеты',
        moderation: 'Модерация',
        activity: 'Активности',
        instructions: 'Инструкции',
        deletedHumans: 'Удаленные студенты',
    },
    fields: {
        login: {
            placeholder: 'Введите свой логин',
            errors: {
                default: 'Необходимо указать свой логин',
            },
        },
        password: {
            placeholder: 'Введите свой пароль',
            errors: {
                default: 'Необходимо указать свой пароль',
            },
        },
    },
    upload:{
        title: 'Загрузите заполненный шаблон',
        uploadButton: 'Загрузить'
    },
    humans: {
        add: 'Добавить студента',
        import: 'Импорт студентов',
        create: 'Создать студента',
        total: 'Нет студентов | {n} студент | {n} студента | {n} студентов',
        isActive: 'Активен',
        lostCard: 'Карта утеряна',
        linkCard: 'Привязать карту',
        removeHuman: 'Удалить студента',
        showHuman: 'Информация о студенте',
        edit: 'Редактировать',
        noData: 'Студентов нет',
        notifications: {
            successHumanRemove: 'Студент успешно удален',
            successLostCard: 'Карта студента успешно удалена',
            successSetActive: 'Статус студента успешно изменен',
        },
        removeHumanConfirm: {
            question: 'Вы действительно хотите удалить студента?',
            ok: 'Да',
            cancel: 'Нет',
        },
        initials: 'Инициалы',
        iin: 'ИИН',
        rfid: 'Код карты',
        enteredAt: 'Дата поступления',
    },
    users: {
        add: 'Добавить пользователя',
        create: 'Создать пользователя',
        total: 'Нет пользователей | {n} пользователь | {n} пользователя | {n} пользователей',
        isActive: 'Активен',
        removeUser: 'Удалить пользователя',
        showUser: 'Информация о пользователе',
        edit: 'Редактировать',
        noData: 'Пользователей нет',
        notifications: {
            successUserRemove: 'Пользователь успешно удален',
            successSetActive: 'Статус пользователя успешно изменен',
        },
        removeUserConfirm: {
            question: 'Вы действительно хотите удалить пользователя?',
            ok: 'Да',
            cancel: 'Нет',
        },
        initials: 'Инициалы',
        iin: 'ИИН',
        phone: 'Номер телефона',
        rfid: 'Код карты',
        login: 'Логин',
        role: 'Роль',
        status: 'Статус',
        position: 'Роль',
        active: 'Активен',
        inactive: 'Не активен',
        resetPassword: 'Сбросить пароль',
    },
    entrances: {
        add: 'Добавить общежитие',
        name: 'Название',
        cityName: 'Город',
        address: 'Адрес',
        capacity: 'Вместимость',
        noData: 'Общежитий нет',
        removeEntranceConfirm: {
            question: 'Вы действительно хотите удалить общежитие?',
            ok: 'Да',
            cancel: 'Нет',
        },
        notifications: {
            successEntranceRemove: 'Общежитие успешно удалено',
        },
    },
    turnstiles: {
        noData: 'Турникетов нет',
    },
    ui: {
        search: 'Поиск..',
    },
    confirm: 'Подтверждение действия',
    notifications: {
        success: 'Успех',
    },
    modals: {
        checkCard: {
            title: 'Проверка студента',
            humanNotExist: 'Карточка не привязана к студенту',
            humanExist: 'Карточка привязана к студенту другого общежития',
        },
        entrance: {
            titleAdd: 'Создание общежития',
            titleEdit: 'Редактирование общежития',
            btnAdd: 'Создать',
            btnEdit: 'Сохранить',
            fields: {
                name: {
                    label: 'Наименование общежития',
                    placeholder: 'Укажите название общежития',
                    error: 'Укажите название общежития',
                },
                companyName: {
                    label: 'Наименование компании',
                    placeholder: 'Укажите наименование компании',
                    error: 'Укажите наименование компании',
                },
                city: {
                    label: 'Город',
                    placeholder: 'Выберите город',
                    error: 'Выберите город',
                },
                capacity: {
                    label: 'Вместимость',
                    placeholder: 'Укажите вместимость',
                    error: 'Укажите вместимость',
                },
                address: {
                    label: 'Адрес общежития',
                    placeholder: 'Укажите адрес',
                    error: 'Укажите адрес',
                },
                login: {
                    label: 'Логин',
                    placeholder: 'Укажите логин',
                    error: 'Укажите логин',
                },
                password: {
                    label: 'Пароль',
                    placeholder: 'Укажите пароль',
                    error: 'Укажите пароль',
                    error2: 'Пароли не совпадают',
                },
                confirm: {
                    label: 'Подтверждение пароля',
                    placeholder: 'Подтвердите пароль',
                    error: 'Подтвердите пароль',
                    error2: 'Пароли не совпадают',
                },
                firstName: {
                    label: 'Имя',
                    placeholder: 'Укажите имя',
                    error: 'Укажите имя',
                },
                lastName: {
                    label: 'Фамилия',
                    placeholder: 'Укажите фамилию',
                    error: 'Укажите фамилию',
                },
                middleName: {
                    label: 'Отчество',
                    placeholder: 'Укажите отчество',
                    error: 'Укажите отчество',
                },
                iin: {
                    label: 'ИИН',
                    placeholder: 'Укажите ИИН',
                    error: 'Укажите ИИН',
                },
                bin: {
                    label: 'БИН',
                    placeholder: 'Укажите БИН',
                    error: 'Укажите БИН',
                },
                bin_yl: {
                    label: 'БИН ЮЛ',
                    placeholder: 'Укажите БИН ЮЛ',
                    error: 'Укажите БИН ЮЛ',
                },
                phone: {
                    label: 'Номер телефона',
                    placeholder: 'Укажите номер телефона',
                    error: 'Укажите номер телефона',
                },
            },
        },
        turnstile: {
            titleAdd: 'Создание турникета',
            titleEdit: 'Редактирование турникета',
            btnAdd: 'Создать',
            btnEdit: 'Сохранить',
            fields: {
                name: {
                    label: 'Наименование турникета',
                    placeholder: 'Укажите название турникета',
                    error: 'Укажите название турникета',
                },
                entrance: {
                    label: 'Общежитие',
                    placeholder: 'Выберите общежитие',
                    error: 'Выберите общежитие',
                },
                sn: {
                    label: 'Код турникета',
                    placeholder: 'Укажите код турникета',
                    error: 'Укажите код турникета',
                },
                reverseDirection: {
                    label: 'Турникет установлено не правильно',
                },
            },
        },
        rejectModeration: {
            title: 'Выберите причину отклонения',
        },
    },
};
